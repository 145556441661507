import React from 'react';

export default function ContactInfo(props: {
  title: string;
  value: string;
  url?: string;
}) {
  return (
    <div className='profile-contact-detail'>
      <strong>{props.title}: </strong>
      {props.url ? <a href={props.url}>{props.value}</a> : props.value}
    </div>
  );
}
