import Header from './Header';
import Profile from './Profile';
import { useParams } from 'react-router-dom';

function User(props: any) {
  let { id } = useParams();

  return (
    <>
      <Header />
      <Profile id={id} />
    </>
  );
}

export default User;
