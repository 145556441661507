import './App.scss';
import User from './components/User';
import VCard from './components/UserVCard';
import Home from './components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className='app'>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/:id' element={<User />} />
          <Route path='/vcard/:id' element={<VCard />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
