import React, { useEffect, useState } from 'react';
import ContactInfo from './ContactInfo';
import SocialMedia from './SocialMedia';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

export default function Profile(props: any) {
  interface SocialMedia {
    type: string;
    url: string;
  }

  interface SocialMediaUrls {
    [index: number]: SocialMedia;
  }

  interface IUser {
    id: string;
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    address: string;
    socialMediaUrls: SocialMediaUrls;
  }

  const [user, setUser] = useState<IUser>();

  useEffect(() => {
    if (props.id) {
      fetch('https://nfc.gurcanakyuz.com/users/' + props.id)
        .then((response) => {
          if (response.status === 400) {
            Swal.fire({
              title: 'Error!',
              text: 'User not found',
              icon: 'error',
              confirmButtonText: 'Ok!',
            });
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data !== 'undefined') {
            if (typeof data.id !== 'undefined') {
              setUser(data);
            }
          }
        });
    }
  }, []);

  return (
    <>
      {user ? (
        <div className='profile'>
          <div className='profile-header'>
            <div className='profile-image'>
              <img
                src={'https://nfc.gurcanakyuz.com/users/images/' + props.id}
                alt={user.name + ' ' + user.surname}
              />
            </div>
          </div>
          <div className='profile-info'>
            <h2>
              {user.name} {user.surname}
            </h2>
            <Link to={'/vcard/' + props.id} className='saveToContacts'>
              Rehbere Ekle
            </Link>
            <div className='profile-contact-info'>
              {user.email && (
                <ContactInfo
                  title='E-Posta'
                  value={user.email}
                  url={'mailto:' + user.email}
                />
              )}

              {user.phoneNumber && (
                <ContactInfo
                  title='Telefon'
                  value={user.phoneNumber}
                  url={'tel:' + user.phoneNumber}
                />
              )}

              {user.address && (
                <ContactInfo title='Adres' value={user.address} />
              )}
            </div>
            <h5>Sosyal Medya</h5>
            <div className='social-media-addresses'>
              {user.socialMediaUrls instanceof Array &&
                user.socialMediaUrls.map(
                  (socaialMediaLink: SocialMedia, index: number) => {
                    return (
                      <SocialMedia
                        key={index}
                        url={socaialMediaLink.url}
                        type={socaialMediaLink.type}
                      />
                    );
                  }
                )}
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
}
