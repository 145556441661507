import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { Navigate, useParams } from 'react-router-dom';
import VCard from 'vcard-creator';

export default function UserVCard(props: any) {
  interface SocialMedia {
    type: string;
    url: string;
  }

  interface SocialMediaUrls {
    [index: number]: SocialMedia;
  }

  interface User {
    id: string;
    name: string;
    surname: string;
    email: string;
    phoneNumber: string;
    address: string;
    socialMediaUrls: SocialMediaUrls;
  }

  const [goTo, setGoTo] = useState<boolean>(false);
  const [userId, setUserId] = useState<any>();

  let { id } = useParams();
  if (!userId && id) {
    setUserId(id);
  }

  useEffect(() => {
    if (id) {
      fetch('https://nfc.gurcanakyuz.com/users/' + id)
        .then((response) => {
          if (response.status === 400) {
            Swal.fire({
              title: 'Error!',
              text: 'User not found',
              icon: 'error',
              confirmButtonText: 'Ok!',
            });
          }
          return response.json();
        })
        .then((data) => {
          if (typeof data !== 'undefined') {
            if (typeof data.id !== 'undefined') {
              const myVCard = new VCard();

              // Some variables
              const lastname = data.surname;
              const firstname = data.name;
              const additional = '';
              const prefix = '';
              const suffix = '';

              myVCard
                // Add personal data
                .addName(lastname, firstname, additional, prefix, suffix)
                // Add work data
                .addEmail(data.email)
                .addPhoneNumber(data.phoneNumber, 'PREF;WORK')
                .addPhoneNumber(data.phoneNumber, 'WORK');

              //save to file
              // vCard.saveToFile('./eric-nesser.vcf');

              //get as formatted string
              // console.log(myVCard.getFormattedString());

              const element = document.createElement('a');
              const file = new Blob([myVCard.toString()], {
                type: 'text/vcard',
              });
              element.href = URL.createObjectURL(file);
              element.download = 'vcard.vcf';
              document.body.appendChild(element); // Required for this to work in FireFox
              element.click();

              setGoTo(true);
            }
          }
        });
    }
  }, []);
  return <div>{goTo && <Navigate to={'/' + id} />}</div>;
}
