import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTwitter,
  faFacebook,
  faLinkedin,
  faInstagram,
  faGoogle,
  faBehance,
  faYoutube,
  faTiktok,
  faSnapchat,
  faSkype,
  faWhatsapp,
  faPinterest,
  faSpotify,
} from '@fortawesome/free-brands-svg-icons';

export default function SocialMedia(props: { type: string; url: string }) {
  let icon, path;

  switch (props.type) {
    case 'twitter':
      icon = <FontAwesomeIcon icon={faTwitter} size='4x' />;
      path = 'https://www.twitter.com/';
      break;
    case 'facebook':
      icon = <FontAwesomeIcon icon={faFacebook} size='4x' />;
      path = 'https://www.facebook.com/';
      break;
    case 'linkedin':
      icon = <FontAwesomeIcon icon={faLinkedin} size='4x' />;
      path = 'https://www.linkedin.com/in/';
      break;
    case 'instagram':
      icon = <FontAwesomeIcon icon={faInstagram} size='4x' />;
      path = 'https://www.instagram.com/';
      break;
    case 'google':
    case 'gmail':
    case 'gtalk':
      icon = <FontAwesomeIcon icon={faGoogle} size='4x' />;
      break;
    case 'behance':
      icon = <FontAwesomeIcon icon={faBehance} size='4x' />;
      break;
    case 'youtube':
      icon = <FontAwesomeIcon icon={faYoutube} size='4x' />;
      path = 'https://www.youtube.com/';
      break;
    case 'tiktok':
      icon = <FontAwesomeIcon icon={faTiktok} size='4x' />;
      break;
    case 'snapchat':
      icon = <FontAwesomeIcon icon={faSnapchat} size='4x' />;
      path = 'https://www.snapchat.com/';
      break;
    case 'skype':
      icon = <FontAwesomeIcon icon={faSkype} size='4x' />;
      break;
    case 'whatsapp':
      icon = <FontAwesomeIcon icon={faWhatsapp} size='4x' />;
      break;
    case 'pinterest':
      icon = <FontAwesomeIcon icon={faPinterest} size='4x' />;
      break;
    case 'spotify':
      icon = <FontAwesomeIcon icon={faSpotify} size='4x' />;
      break;
  }

  return (
    <div className='profile-social-media'>
      <a href={path + props.url} target='_blank' data-provider={props.type}>
        {icon}
      </a>
    </div>
  );
}
